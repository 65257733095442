import React, { useEffect } from 'react';
import AuthenticatedContext from '../../auth/AuthenticatedContext';
import TenantPermissionProvider from '../../services/TenantPermissionProvider';
import HandlerLayout from './HandlerLayout';
import LoadingSuspense from '../../components/LoadingSuspense';
import RouteWrapper from '../../components/RouteWrapper';
import TenantPermissions from '../../enums/TenantPermissions';
import AdminRouteWrapper from '../../components/AdminRouteWrapper';
import useLogout from '../../auth/useLogout';
import { useUserHubConnection } from '../../services/UserHubConnectionProvider';
import useUserId from '../../hooks/useUserId';
import { Redirect } from 'react-router-dom';

const HandlerDashboard = React.lazy(() => import('./HandlerDashboard'));
const ClaimNavigation = React.lazy(() => import('../claim-navigation/ClaimNavigation'));
const AdminPage = React.lazy(() => import('../admin/AdminPage'));
const CreateHandler = React.lazy(() => import('../create-handler/CreateHandler'));
const ViewHandlers = React.lazy(() => import('../claim-handlers/view-handlers/ViewHandlers'));
const HandlerProfile = React.lazy(() => import('../claim-handlers/handler-profile/HandlerProfile'));

function HandlerRoutesWrapper() {
    const logout = useLogout();
    const userHubConnection = useUserHubConnection();
    const userId = useUserId();

    useEffect(() => {
        if (userId) {
            userHubConnection.on('UserDisabled', (user) => {
                if (userId === user.id) {
                    logout();
                }
            });
        }
    }, [userHubConnection, logout, userId]);

    return (
        <TenantPermissionProvider permission={TenantPermissions.Handler}>
            <AuthenticatedContext>
                <HandlerLayout>
                    <LoadingSuspense>
                        <Redirect exact from='/' to='/dashboard' />
                        <RouteWrapper exact path='/dashboard' component={HandlerDashboard} />
                        <RouteWrapper exact path='/claim/:id/:page' component={ClaimNavigation} />
                        <AdminRouteWrapper exact path='/admin/create-handler' component={CreateHandler} />
                        <AdminRouteWrapper exact path='/admin' component={AdminPage} />
                        <AdminRouteWrapper exact path='/admin/view-handlers' component={ViewHandlers} />
                        <AdminRouteWrapper exact path='/admin/handler/:id' component={HandlerProfile} />
                    </LoadingSuspense>
                </HandlerLayout>
            </AuthenticatedContext>
        </TenantPermissionProvider>
    );
}

export default HandlerRoutesWrapper;
