import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import RedirectingToLogin from './RedirectingToLogin';
import React, { useState, useEffect } from 'react';

function AuthenticatedContext({ children }) {
    const { isAuthenticated, isLoading } = useAuth0();
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            setIsReady(true);
        }
    }, [isLoading]);

    if (isLoading) {
        return null;
    }

    return isReady && isAuthenticated ? children : <RedirectingToLogin />;
}

AuthenticatedContext.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthenticatedContext;