import React, { createContext, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { isJsonResponse } from '../utils/isJsonResponse';
import { isPdfResponse } from '../utils/isPdfResponse';
import { useAuth0 } from '@auth0/auth0-react';
import useLogout from '../auth/useLogout';
import useIsAdminHandler from './useIsAdminHandler';
import { useAuth0Configs } from '../services/ApiConfigsContextProvider';

export function useFetcher() {
    const { isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
    const logout = useLogout();
    const isAdmin = useIsAdminHandler();
    const auth0Config = useAuth0Configs();

    return useCallback(
        async (url, options = {}) => {
            const { headers = {}, body, ...otherOptions } = options;
            const bodyStringify = body ? JSON.stringify(body) : null;
            let accessToken = null;

            if (isAuthenticated) {
                try {
                    accessToken = await getAccessTokenSilently({
                        authorizationParams: {
                            audience: auth0Config.audience,
                            scope: isAdmin ? "admin" : "",
                        },
                    });
                } catch (error) {
                    if (error.error === 'consent_required' || error.error === 'login_required') {
                        accessToken = await getAccessTokenWithPopup({
                            authorizationParams: {
                                audience: auth0Config.audience,
                                scope: isAdmin ? "admin" : "",
                            },
                        });
                    } else {
                        throw error;
                    }
                }
            }

            const response = await fetch(url, {
                method: options.method || 'GET',
                headers: new Headers({
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    ...headers,
                }),
                body: bodyStringify,
                ...otherOptions,
            });
            if (response.status === 401) {
                logout();
                return;
            }

            let responseData;
            if (isJsonResponse(response)) {
                responseData = await response.json();
            } else if (isPdfResponse(response)) {
                responseData = await response.blob();
            } else {
                responseData = await response.text();
            }

            if (response.status >= 400) {
                throw new Error(`HTTP ${response.status} - ${responseData}`);
            }
            return responseData;
        },
        [getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated, isAdmin, logout, auth0Config.audience]
    );
}

const FetcherContext = createContext(null);
export const useQueryFetcher = () => useContext(FetcherContext);
export function FetcherProvider({ children }) {
    const fetcher = useFetcher();
    return (
        <FetcherContext.Provider value={fetcher}>
            {children}
        </FetcherContext.Provider>
    );
}

FetcherProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
