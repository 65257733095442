import React, { useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';
import GlobalThemeProvider from './themes/GlobalThemeProvider';
import LoadingSuspense from './components/LoadingSuspense';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';
import { Typo } from 'ri-components';
import { Box } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import PropTypes from 'prop-types';
import { FetcherProvider, useQueryFetcher } from './hooks/useFetcher';
import HandlerRoutesWrapper from './pages/handler-dashboard/HandlerRoutesWrapper';
import { withTracking } from './services/AppInsightsProvider';
import { ReactQueryDevtools } from 'react-query/devtools';
import Unauthorized from './pages/unauthorized/Unauthorized';
import UserHubConectionProvider from './services/UserHubConnectionProvider';
import { useTranslation } from 'react-i18next';
import { Auth0Provider } from '@auth0/auth0-react';
import UserProvider from './auth/UserProvider';
import { useAuth0Configs } from './services/ApiConfigsContextProvider';

const handlerPaths = [
    '/',
    '/dashboard',
    '/claim/:id/:page',
    '/admin/create-handler',
    '/admin/view-handlers',
    '/admin',
    '/admin/handler/:id',
];

const App = () => {
    const { t } = useTranslation();
    if (typeof Node === 'function' && Node.prototype) {
        const originalRemoveChild = Node.prototype.removeChild;
        Node.prototype.removeChild = function (child) {
            if (child.parentNode !== this) {
                if (console) {
                    console.error('Cannot remove a child from a different parent', child, this);
                }
                return child;
            }
            return originalRemoveChild.apply(this, arguments);
        };

        const originalInsertBefore = Node.prototype.insertBefore;
        Node.prototype.insertBefore = function (newNode, referenceNode) {
            if (referenceNode && referenceNode.parentNode !== this) {
                if (console) {
                    console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
                }
                return newNode;
            }
            return originalInsertBefore.apply(this, arguments);
        };
    }
    return (
        <Switch>
            <Route exact path={handlerPaths}>
                <UserHubConectionProvider>
                    <HandlerRoutesWrapper />
                </UserHubConectionProvider>
            </Route>
            <Route exact path='/unauthorized'>
                <Unauthorized />
            </Route>
            <Route>
                <Box textAlign='center'>
                    <Typo variant='title2'>{t('404 - Page does not exists')}</Typo>
                </Box>
            </Route>
        </Switch>
    );
};

function AppWrapper() {
    const auth0Config = useAuth0Configs();
    if (!auth0Config.domain) throw new Error("Failed to retrieve authentication configurations.")
    return (
        <GlobalThemeProvider>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <LoadingSuspense>
                    <Auth0Provider
                        domain={auth0Config.domain}
                        clientId={auth0Config.clientId}
                        authorizationParams={{
                            redirect_uri: window.location.origin,
                            audience: auth0Config.audience,
                            scope: "read:current_user",
                        }}
                    >
                        <UserProvider>
                            <FetcherProvider>
                                <QueryClientProviderWrapper>
                                    <App />
                                </QueryClientProviderWrapper>
                            </FetcherProvider>
                        </UserProvider>
                    </Auth0Provider>
                </LoadingSuspense>
            </ErrorBoundary>
        </GlobalThemeProvider>
    );
}
const QueryClientProviderWrapper = React.memo(({ children }) => {
    const fetcher = useQueryFetcher();
    const queryClient = useMemo(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        queryFn: (ctx) => fetcher(ctx.queryKey[0]),
                        refetchOnWindowFocus: false,
                    },
                },
            }),
        [fetcher],
    );

    return (
        <QueryClientProvider client={queryClient}>
            {children}
            <ReactQueryDevtools />
        </QueryClientProvider>
    );
});

QueryClientProviderWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default withTracking(AppWrapper);
